import { defineStore } from "pinia";
import { useNuxtApp, showError } from "nuxt/app";
import { $useFetch } from "~/composables/useFetch";

export const useOrganizersStore = defineStore("organizers", {
	state: () => ({
		groups: [],
		organizers: [],
		agents: [],
		all: [],
	}),
	actions: {
		async fetchOrganizers() {
			const { api } = useNuxtApp();
			const organizersStore = useOrganizersStore();

			const { error } = await $useFetch(api.users(), {
				method: "GET",
				onResponse({ response }) {
					if (response?.status === 200) {
						// groups
						organizersStore.groups = response._data.data?.groups;

						// organisators
						organizersStore.organizers = response._data.data?.groups.map((el) => el.organisator);

						// agents
						organizersStore.agents = [];
						response._data.data?.groups.map((el) => el.agents.map((item) => organizersStore.agents.push(item)));

						// all users
						organizersStore.all = organizersStore.organizers.concat(organizersStore.agents);
					}
				},
			});

			if (error.value) {
				throw showError({ statusCode: 500, statusMessage: error.value.message });
			}
		},
	},
	getters: {
		getGroups() {
			return this.groups;
		},

		// ************
		// ОРГАНИЗАТОР
		// ************

		// админ: массив объектов организаторов
		getOrganizers() {
			return this.organizers;
		},

		// админ: список имён всех организаторов
		getOrganizersNamed() {
			const organizersByName = [];
			for (const item in this.organizers) {
				organizersByName.push(this.organizers[item].name);
			}
			return organizersByName;
		},

		// админ: получение id организатора по имени
		getOrganizersIdByName: (state) => (name) => {
			for (const item in state.organizers) {
				if (state.organizers[item].name === name) return state.organizers[item].id;
			}
		},

		// админ: получение имени организатора по id
		getOrganizersNameById: (state) => (id) => {
			for (const item in state.organizers) {
				if (state.organizers[item].id === id) return state.organizers[item].name;
			}
		},

		// админ: получить имя организатора по id агента
		getOrganizerNameByAgentId: (state) => (id) => {
			const orgId = state.agents.find((val) => val.id === id)?.organisator;
			return state.organizers.find((val) => val.id === orgId)?.name || "";
		},

		// админ: поиск организатора по id агента
		getOrganizerByAgentId: (state) => (id) => {
			const orgId = state.agents.find((val) => val.id === id)?.organisator;
			return state.organizers.find((val) => val.id === orgId);
		},

		// ************
		// АГЕНТЫ
		// ************

		// админ, агент: массив объектов агентов
		getAgents() {
			return this.agents;
		},

		// админ, орг: получить агента по id
		getAgentById(state) {
			return (id) => state.agents.find((val) => val.id === id);
		},

		// админ, орг: получить имя агента по id
		getAgentNameById(state) {
			return (id) => state.agents.find((val) => val.id === id)?.name;
		},

		// админ: поиск агента по id организатора
		getAgentByOrganizerId: (state) => (id) => state.agents.find((val) => val.organisator === id),

		// админ: список агентов по id организатора
		getAgentsByOrganizerId: (state) => (id) =>
			state.agents
				.map((val) => {
					if (val.organisator === id) return val;
					else return null;
				})
				.filter((val) => val),

		// ************
		// ВСЕ ПОЛЬЗОВАТЕЛИ
		// ************
		getAllUsers() {
			return Object.assign(this.all);
		},
		getUserById: (state) => (id) => state.all.map((val) => (val.id === id ? val : null)).filter((val) => val),
		getUserNameById: (state) => (id) => state.all.map((val) => (val.id === id ? val : null)).filter((val) => val)[0]?.name || "",
	},
});
